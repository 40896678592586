import { LoanPurposeActivity } from '~/enums/LoanPurposeEnum';
import { LoanPurposeWhat } from '~/enums/LoanPurposeWhat';
import { MarketType } from '~/enums/MarketType';
import {
  DEFAULT_CASH_LOAN_AMOUNT,
  DEFAULT_CASH_LOAN_PERIOD,
  DEFAULT_LOAN_PERIOD_IN_YEARS,
  MAX_CASH_LOAN_AMOUNT,
  MAX_CASH_LOAN_PERIOD,
  MAX_HYPOTHEC_VALUE,
  MAX_MORTGAGE_LOAN_PERIOD,
  MIN_CASH_LOAN_AMOUNT,
  MIN_CASH_LOAN_PERIOD,
  MIN_CREDIT_VALUE,
  MIN_HYPOTHEC_VALUE,
  MIN_MORTGAGE_LOAN_PERIOD,
  MIN_SAVINGS_PERCENT,
  MIN_SAVINGS_PERCENT_WITHOUT_ADDITIONAL_COSTS
} from '~/service/constants/simulationConstants';
import { toCurrency } from '~/filters/Filters';
import type { LendiQuery } from "~/models/UTMs/UTMs";
import { wait } from "~/service/Utils";

export type MortgageSimulationFormInput = {
  loanAmount?: number,
  loanPeriod?: number,
  savings: number,
  hypothecValue: number,
  collateral: LoanPurposeWhat,
  loanPurposeActivity: LoanPurposeActivity,
  market: MarketType | null,
  offerId?: string
}

export type CashSimulationFormInput = {
  loanPeriod: number,
  loanAmount: number,
}

const DEFAULT_HYPOTHEC_VALUE = 400_000;
const DEFAULT_SAVINGS = 100_000;

export function useMortgageSimpleSimulationStore() {
  const collateral = useState<LoanPurposeWhat>('collateral', () => LoanPurposeWhat.APARTMENT);
  const hypothecValue = useState<number>('hypothecValue', () => DEFAULT_HYPOTHEC_VALUE);
  const savings = useState<number>('savings', () => DEFAULT_SAVINGS);
  const loanPeriod = useState<number>('loanPeriod', () => DEFAULT_LOAN_PERIOD_IN_YEARS);
  const market = useState<MarketType>('market', () => MarketType.PRIMARY);
  const loanPurposeActivity = useState<LoanPurposeActivity>('loanPurposeActivity', () => LoanPurposeActivity.BUYING);
  const { openWarningSnackbar } = useSnackbar();
  /**@Warning same name like in cash simple simulation store*/
  const simulationFormInput = computed<MortgageSimulationFormInput>(() => ({
    collateral: collateral.value,
    hypothecValue: hypothecValue.value,
    savings: savings.value,
    loanPeriod: loanPeriod.value,
    market: market.value,
    loanPurposeActivity: loanPurposeActivity.value,
  }))
  const loanAmount = computed(() => hypothecValue.value - savings.value);
  const savingsPercent = computed(() => Math.round(100 * savings.value / hypothecValue.value));
  /** @INFO In mortgage loanPeriod is in years and installment is every month...*/
  const installmentsCount = computed(() => loanPeriod.value * 12);

  const initStore = () => {
    const { query } = useQuery<LendiQuery>();
    const hypothecValue = typeof query.hypothecValue === 'string' ? parseInt(query.hypothecValue) : query.hypothecValue;
    const passedLoanAmount = query.creditValue as number || query.loanAmount as number;
    const passedLoanPeriod = query.loanPeriod as number

    if (passedLoanAmount && hypothecValue) {
      setHypothecValue(hypothecValue);
      if (!query.savings) {
        setSavings(hypothecValue as number - passedLoanAmount);
      }
    }
    if (passedLoanAmount && !query.hypothecValue) {
      setHypothecValueAndSavingsWithPercentCalculation(passedLoanAmount);
    }
    if (query.savings) {
      setSavings(query.savings);
    }
    if (passedLoanPeriod) {
      setLoanPeriod(passedLoanPeriod);
    }
    if (hypothecValue && !passedLoanAmount) {
      setHypothecValue(hypothecValue);
      if (!query.savings) {
        setMinSavingsWithoutAdditionalCosts();
      }
    }
  };

  const setSavings = async (newSavings: number) => {
    const maxValue = hypothecValue.value - MIN_CREDIT_VALUE;
    const minValue = Math.ceil(hypothecValue.value * MIN_SAVINGS_PERCENT / 100);
    savings.value = newSavings;
    /**@info wait is necessary for input to wait for values like hyp. val. and savings change when being auto-corrected
     * before triggering offer fetch in /kredyt-hipoteczny/bankSeoName */
    await wait(1);

    if (newSavings > maxValue) {
      savings.value = maxValue;
      openWarningSnackbar('Dostosowaliśmy wkład własny do ceny nieruchomości.', 'top');
    } else if (newSavings < minValue) {
      savings.value = minValue;
      openWarningSnackbar(`Minimalny wkład własny to ${MIN_SAVINGS_PERCENT}% ceny nieruchomosci.`, 'top');
    }
  };

  const setLoanPeriod = (newLoanPeriod: number) => {
    const maxValue = MAX_MORTGAGE_LOAN_PERIOD;
    const minValue = MIN_MORTGAGE_LOAN_PERIOD;
    loanPeriod.value = newLoanPeriod > maxValue
      ? maxValue
      : newLoanPeriod < minValue
        ? minValue
        : Math.ceil(newLoanPeriod / 5) * 5; // round to 5
  };

  const setMinSavingsWithoutAdditionalCosts = () => {
    savings.value = Math.round(hypothecValue.value * MIN_SAVINGS_PERCENT_WITHOUT_ADDITIONAL_COSTS / 100);
  };

  /**@Info Ustawia procentową wartość kwoty kredytu względem domyślnej dla savings oraz hypothecValue */
  const setHypothecValueAndSavingsWithPercentCalculation = (newValue: number) => {
    const creditPercent = newValue / loanAmount.value;
    hypothecValue.value = Math.round(hypothecValue.value * creditPercent);
    setSavings(Math.round(savings.value * creditPercent));
  };

  const setHypothecValue = async (newValue: number | string) => {
    const { openWarningSnackbar } = useSnackbar();
    const minValue = MIN_HYPOTHEC_VALUE;
    const maxValue = MAX_HYPOTHEC_VALUE;
    const value = typeof newValue === 'string' ? parseInt(newValue) : newValue;
    hypothecValue.value = value;
    /**@info wait is necessary for input to wait for values like hyp. val. and savings change when being auto-corrected
     * before triggering offer fetch in /kredyt-hipoteczny/bankSeoName */
    await wait(1);

    if (value < minValue) {
      hypothecValue.value = minValue;
      openWarningSnackbar(`Minimalna cena nieruchomości to ${toCurrency(MIN_HYPOTHEC_VALUE)}`, 'top');
    } else if (value > maxValue) {
      hypothecValue.value = maxValue;
      openWarningSnackbar(`Maksymalna cena nieruchomości to ${toCurrency(MAX_HYPOTHEC_VALUE)}`, 'top');
    }
    await setSavings(savings.value);
  };

  return {
    collateral,
    hypothecValue,
    savings,
    loanPeriod,
    market,
    loanPurposeActivity,
    loanAmount,
    savingsPercent,
    installmentsCount,
    simulationFormInput,
    initStore,
    setSavings,
    setLoanPeriod,
    setMinSavingsWithoutAdditionalCosts,
    setHypothecValue,
  };
};

export function useCashSimpleSimulationStore() {
  const loanAmount = useState<number>('loanAmountCash', () => DEFAULT_CASH_LOAN_AMOUNT);
  const loanPeriod = useState<number>('loanPeriodCash', () => DEFAULT_CASH_LOAN_PERIOD);
  /** @INFO In cash loanPeriod is really loanPeriodInMonths */
  const installmentsCount = computed<number>((state) => loanPeriod.value);
  /**@Warning same name like in mortgage simple simulation store*/
  const simulationFormInput = computed<CashSimulationFormInput>(() => ({
    loanAmount: loanAmount.value,
    loanPeriod: loanPeriod.value,
  }));
  const initStore = () => {
    const { getNonArrayQuery, } = useQuery();
    const query = getNonArrayQuery();
    const passedLoanAmount: number | undefined = query.creditValue as number ?? query.loanAmount as number;
    const passedLoanPeriod: number | undefined = query.loanPeriod as number;
    if (passedLoanAmount) {
      setLoanAmount(passedLoanAmount);
    }
    if (passedLoanPeriod) {
      setLoanPeriod(passedLoanPeriod);
    }
  }
  const setLoanAmount = (newValue: number) => {
    loanAmount.value = newValue > MAX_CASH_LOAN_AMOUNT
      ? MAX_CASH_LOAN_AMOUNT
      : newValue < MIN_CASH_LOAN_AMOUNT
        ? MIN_CASH_LOAN_AMOUNT
        : Math.floor(newValue);
  }
  const setLoanPeriod = (newValue: number) => {
    loanPeriod.value = newValue > MAX_CASH_LOAN_PERIOD
      ? MAX_CASH_LOAN_PERIOD
      : newValue < MIN_CASH_LOAN_PERIOD
        ? MIN_CASH_LOAN_PERIOD
        : Math.floor(newValue);
  }
  return {
    loanPeriod,
    loanAmount,
    installmentsCount,
    simulationFormInput,
    setLoanPeriod,
    setLoanAmount,
    initStore
  }
}
